import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ProfileDataService } from './data/personal/profile.data.service';
import { UpdateProfileRequestInterface } from '../interfaces/request/profile/update-profile.request.interface';
import { FindResponseInterface } from '../interfaces/response/find.response.interface';
import { ProfileInterface } from '../interfaces/model/profile.interface';
import { SaveResponseInterface } from '../interfaces/response/save.response.interface';
import { MessageResponseInterface } from '../interfaces/response/message.response.interface';
import { AuthHelper } from '../helper/auth.helper';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private api: ApiService,
    private profileDataService: ProfileDataService,
    private authHelper: AuthHelper,
  ) {
    // Noop
  }

  public refresh(): Promise<FindResponseInterface<ProfileInterface>> {
    return this.api.get('/profile')
      .then(response => this.profileDataService.pokeProfileByProfileResponse(response));
  }

  public updateProfile(request: UpdateProfileRequestInterface): Promise<SaveResponseInterface<ProfileInterface>> {
    return this.api.put('/profile', request)
      .then(response => {
        this.profileDataService.pokeProfileByProfileResponse(response);

        return response;
      });
  }

  public updateLocale(locale: string): Promise<FindResponseInterface<ProfileInterface>> {
    const body = {
      locale: locale
    };

    return this.api.post('/profile/locale', body)
      .then(response => {
        this.profileDataService.pokeProfileByProfileResponse(response);

        return response;
      });
  }

  public updateAvatar(imageBlob: Blob): Promise<SaveResponseInterface<ProfileInterface>> {
    const formData = new FormData();

    formData.append('avatar', imageBlob);

    return this.api.postMultiPartFormData('/profile/avatar', formData)
      .then(response => {
        this.profileDataService.pokeProfileByProfileResponse(response);

        return response;
      });
  }

  public deleteProfileAvatar(): Promise<SaveResponseInterface<ProfileInterface>> {
    return this.api.delete('/profile/avatar')
      .then(response => {
        this.profileDataService.pokeProfileByProfileResponse(response);

        return response;
      });
  }

  public async deleteProfile(): Promise<MessageResponseInterface> {
    const response = await this.api.post('/profile/delete');
    await this.authHelper.logout();

    return response;
  }

}
